import { Component, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';

@NgModule({
  imports: []
})

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.css']
})
export class QrComponent implements OnInit {
  devices;
  selected;
  success = 'Looking for QR code';
  waitMessage = "Searching for cameras...";

  constructor(private router: Router) { }

  ngOnInit() {
  }

  camerasFoundHandler(event) {
    //console.log(event);
    this.devices = event;
    this.waitMessage = "Please select a camera";
  }

  scanSuccessHandler(event) {
    this.success = 'Scan success!';
    this.router.navigate(['/'+event]);
  }

}
