import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { EzAssetsUrl, EzLocationsUrl } from './constants';
//import { Equipment } from './equipment-model';
import { EzVendor } from './ez-vendor-model';
import { EzSubgroups } from './ez-subgroups-model';
import { EzMembers } from './ez-members-model';
import { EzLocations } from './ez-locations-model';
import { EzGroups } from './ez-groups-model';
import { EzAssets } from './ez-assets-model';


const httpOptions = {
  // Token required to access API
  headers: new HttpHeaders({
    'Content-Type': 'text/plain',
    'token': '005795b97081db8f21341967320a57d7',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class EquipmentRetrieveFromEzoiService {
  data = [];
  page = 1;
  eq: EzAssets;

  constructor(private http : HttpClient) { }

  /* Uses http.get() to load data from a single API endpoint */
  // getAssetsfdsf() {
  //   return this.http.get(EquipmentUrls.assets + EquipmentUrls.page + '1' + EquipmentUrls.urlSuffix);
  // }

  getAssets () {
    return this.http.get<EzAssets[]>( EzAssetsUrl.baseUrl )
  }

  getAsset(sequence_num) {
    return this.http.get<EzAssets[]>( EzAssetsUrl.baseUrl )
  }

  // getGroups () {
  //   return this.http.get<EzAssets[]>( EzAssetsUrl.baseUrl )
  // }

  getLocation() {
    return this.http.get<EzLocations[]>( EzLocationsUrl.baseUrl )
  }
  
  // getEquipment(sequence_num: number): Observable<EzAssets> {
  //   return of(.find(EquipmentData => sequence_num === sequence_num));
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log('Service: ' + message);
  }

}
