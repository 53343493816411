import { Component, OnInit } from '@angular/core';
import { TrackerService } from '../tracker-service';
import { TrackerModel } from '../tracker-model';

import { EzAssets } from '../ez-assets-model';
import { EquipmentRetrieveFromEzoiService } from '../equipment-retrieve-from-ezoi.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-job-overview',
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.css']
})
export class JobOverviewComponent implements OnInit {
  panelOpenState: boolean = false;
  trackerData;
  assetSubscription: Subscription;
  ezAssets;
  
  constructor(private tracker: TrackerService, private eq: EquipmentRetrieveFromEzoiService) { }

  ngOnInit() {
    this.getTrackerData();
    this.getEquipments();
  }

  getTrackerData(): void {
    this.tracker.gettracker().subscribe(
      (resp: TrackerModel[]) => {
      this.trackerData = resp.slice();
      //console.log("Complete: "+ JSON.stringify(this.trackerData));
    },
      (error) => console.log("error: "+ JSON.stringify(error))
    );
  }

  getEquipments(): void {
    //this.equipments = this.EquipmentService.getEquipments();
    this.assetSubscription = this.eq.getAssets().subscribe(
      (ezAssets: EzAssets[]) => {
      this.ezAssets = ezAssets.slice();
      //console.log("Complete: "+ JSON.stringify(ezAssets))
    },
      (error) => console.log("error: "+ JSON.stringify(error))
    );
    
  }

}
