import { Injectable } from '@angular/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TrackerModel } from './tracker-model';
import { Tracker } from './constants';

@Injectable()
export class TrackerService {

  constructor(private http : HttpClient) { }

  gettracker () {
    return this.http.get<TrackerModel[]>( Tracker.baseUrl )
  }

}
