export const EquipmentUrls = {
    baseUrl: "https://pcminc.ezofficeinventory.com/",
    assets: "https://pcminc.ezofficeinventory.com/assets.api?",
    page: "page=",
    urlSuffix: "&include_custom_fields=true&show_document_urls=true&show_image_urls=true&show_document_details=true"
  };

export const EzAssetsUrl = {
  baseUrl: "https://www.pcminc.us/api/get/ez_assets.php"
}

export const EzGroupsUrl = {
  baseUrl: "https://www.pcminc.us/api/get/ez_groups.php"
}

export const EzMembersUrl = {
  baseUrl: "https://www.pcminc.us/api/get/ez_members.php"
}

export const EzLocationsUrl = {
  baseUrl: "https://www.pcminc.us/api/get/ez_locations.php"
}

export const Tracker = {
  baseUrl: "https://www.pcminc.us/api/get/tracker.php"
}

export const Peoples = {
  baseUrl: "https://www.pcminc.us/api/get/employees.php"
}

export const People = {
  baseUrl: "https://www.pcminc.us/api/get/employee.php?eid="
}