import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';

import { PeopleModel } from '../../people-model';
import { PeopleService } from '../../people.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {
  people: PeopleModel;

  constructor(
    private route: ActivatedRoute,
    private ps: PeopleService,
    private location: Location) { }

  ngOnInit() {
    this.getPeople();
  }

  getPeople(): void {
    const eid = this.route.snapshot.paramMap.get('eid');
    
    this.ps.getPeople(eid).subscribe(people => {
      let temp = people.slice(0);
      this.people = temp[0];
    });
  }

  goBack(): void {
    this.location.back();
  }

}