import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { PeopleModel } from './people-model';
import { People, Peoples } from './constants';

@Injectable()
export class PeopleService {
  Peoples: PeopleModel[];
  People: PeopleModel;

  constructor(private http : HttpClient) { }

  getPeoples() {
    return this.http.get<PeopleModel[]>( Peoples.baseUrl );
  }

  getPeople(eid: string) {
    return this.http.get<PeopleModel[]>( People.baseUrl+eid );
  }

}
