import { NgModule } from "@angular/core";
import { MatButtonModule, MatIconModule, MatToolbarModule, MatExpansionModule, MatFormFieldModule } from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';

import {MatSelectModule} from '@angular/material/select';

@NgModule({
    imports: [MatButtonModule, MatIconModule, MatToolbarModule, MatExpansionModule, MatCardModule, MatListModule, MatSidenavModule, MatFormFieldModule, MatPaginatorModule, MatTableModule, MatInputModule, MatSortModule, MatSelectModule],
    exports: [MatButtonModule, MatIconModule, MatToolbarModule, MatExpansionModule, MatCardModule, MatListModule, MatSidenavModule, MatFormFieldModule, MatPaginatorModule, MatTableModule, MatInputModule, MatSortModule, MatSelectModule]
})

export class MaterialModule {}
