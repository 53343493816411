import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
// import firebase from '@firebase/app';
// require('@firebase/auth');
// require('@firebase/database');

import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';

import { LoginComponent } from './auth/login/login.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { EquipmentsComponent } from './equipments/equipments.component';
import { PeoplesComponent } from './peoples/peoples.component';
import { TrucksComponent } from './trucks/trucks.component';
import { EquipmentComponent } from './equipments/equipment/equipment.component';
import { PeopleService } from './people.service';
//import { EquipmentService } from './equipment.service';
import { EquipmentRetrieveFromEzoiService } from './equipment-retrieve-from-ezoi.service';
import { TrackerService } from './tracker-service';
import { environment } from '../environments/environment';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavMainComponent } from './navigation/sidenav-main/sidenav-main.component';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { PeopleComponent } from './peoples/people/people.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { QrComponent } from './qr/qr.component';


//import { ReactiveFormsModule } from '@angular/form-field/r';

import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EquipmentsComponent,
    PeoplesComponent,
    TrucksComponent,
    EquipmentComponent,
    HeaderComponent,
    SidenavMainComponent,
    LoginComponent,
    JobOverviewComponent,
    PeopleComponent,
    UserProfileComponent,
    ChangelogComponent,
    QrComponent
    //ReactiveFormsModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    RouterModule.forRoot(routes, { useHash: false }),
    HttpClientModule,
    ZXingScannerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    CoreModule
  ],
  providers: [PeopleService, EquipmentRetrieveFromEzoiService, TrackerService, AuthGuard, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
