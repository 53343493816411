// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC08O_K35zzwaW_TzN-QL0bFGMJfg_VgPc',
    authDomain: 'pcm-management-suite.firebaseapp.com',
    databaseURL: 'https://pcm-management-suite.firebaseio.com',
    projectId: 'pcm-management-suite',
    storageBucket: 'pcm-management-suite.appspot.com',
    messagingSenderId: '21903147338'
  }
};
