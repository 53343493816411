import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { EquipmentRetrieveFromEzoiService } from '../../equipment-retrieve-from-ezoi.service';
import { EzAssets } from '../../ez-assets-model';
import { EzLocations } from '../../ez-locations-model';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {
  ezAssets: EzAssets[];
  equipment: EzAssets;
  ezLocations: EzLocations[];
  index;

  constructor(
    private route: ActivatedRoute,
    private eq: EquipmentRetrieveFromEzoiService,
    private location: Location) { }

  ngOnInit() {
    this.getEquipment();
    this.getLocation();
  }

  getEquipment(): void {
    const sequence_num = +this.route.snapshot.paramMap.get('sequence_num');
    this.eq.getAsset(sequence_num).subscribe(
      (ezAssets: EzAssets[]) => {
        this.ezAssets = ezAssets;
      },
        (error) => { console.log("error: "+ JSON.stringify(error)) },
        () => { this.equipment = this.ezAssets.find(e => e.sequence_num === sequence_num); }
      );
  }

  getLocation(): void {
    this.eq.getLocation().subscribe(
      (ezLocations: EzLocations[]) => {
        this.ezLocations = ezLocations.slice();
      }
    )
  }

  goBack(): void {
    this.location.back();
  }

}
