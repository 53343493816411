import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-sidenav-main',
  templateUrl: './sidenav-main.component.html',
  styleUrls: ['./sidenav-main.component.css']
})
export class SidenavMainComponent implements OnInit, OnDestroy {
  @Output() sidenavToggle = new EventEmitter<void>();
  isAuth = false;
  authSubscription : Subscription;

  constructor(private authService: AuthService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      // if (data) {
      //   this.user = data;
      //   this.createForm(this.user.name);
      // }
    })
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
    console.log("Hamburger menu pressed");
  }

  onLogout() {
    this.onToggleSidenav();
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }
}
