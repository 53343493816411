import { NgModule }             from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EquipmentsComponent } from './equipments/equipments.component';
import { EquipmentComponent } from './equipments/equipment/equipment.component';
import { PeoplesComponent } from './peoples/peoples.component';
import { PeopleComponent } from './peoples/people/people.component';
import { TrucksComponent } from './trucks/trucks.component';
import { LoginComponent } from './auth/login/login.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { QrComponent } from './qr/qr.component';

/**** Test Component - Delete later */
import { AuthGuard } from './core/auth.guard';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { UserProfileComponent } from './user-profile/user-profile.component';


export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'job-overview', component: JobOverviewComponent, canActivate: [AuthGuard] },
  { path: 'peoples', component: PeoplesComponent, canActivate: [AuthGuard] },
  { path: 'people/:eid', component: PeopleComponent, canActivate: [AuthGuard] },
  { path: 'equipments', component: EquipmentsComponent, canActivate: [AuthGuard]  },
  { path: 'equipment/:sequence_num', component: EquipmentComponent, canActivate: [AuthGuard] },
  { path: 'trucks', component: TrucksComponent, canActivate: [AuthGuard] },
  { path: 'qr', component: QrComponent, canActivate: [AuthGuard] },
  { path: 'account', component: UserProfileComponent },
  { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule],
  providers: [AuthGuard]
})

export class AppRoutingModule {}