import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { EzAssets } from '../ez-assets-model';
import { EquipmentRetrieveFromEzoiService } from '../equipment-retrieve-from-ezoi.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';

const displayedColumnsLong = ['image', 'state', 'name', 'description', 'details'];
const displayedColumnsShort = ['state', 'name', 'description', 'details', ];

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.css']
})
export class EquipmentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  assetSubscription: Subscription;
  //equipments: Equipment[];
  ezAssets;
  length = 0;
  pageSize = 25;
  pageSizeOptions = [10, 25, 50, 100];
  dataSource = new MatTableDataSource<EzAssets>();
  //dataSource =  new MatTableDataSource(ELEMENT_DATA);
  displayedColumns = displayedColumnsLong;
  
  //displayedColumns = ['position', 'name', 'weight', 'symbol'];
  //toolbar buttons properties
    //list display propery
    listSize = true; // true = normal text, small image / false = small but more text, large image

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private eq: EquipmentRetrieveFromEzoiService) { }

  ngOnInit() {
    this.getEquipments();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setListSize(isNormal: boolean) {
    this.listSize = isNormal;
    if (this.listSize) {
      this.displayedColumns = displayedColumnsLong;
    } else {
      this.displayedColumns = displayedColumnsShort;
    }
  }

  getEquipments(): void {
    //this.equipments = this.EquipmentService.getEquipments();
    this.assetSubscription = this.eq.getAssets().subscribe(
      (ezAssets: EzAssets[]) => {
      this.dataSource.data = ezAssets;
      this.length = ezAssets.length;
      //console.log("Complete: "+ JSON.stringify(ezAssets))
    },
      (error) => console.log("error: "+ JSON.stringify(error))
    );
    
  }

  ngOnDestroy() {
    this.assetSubscription.unsubscribe();
  }
}