import { Component, OnInit, ViewChild } from '@angular/core';

import { PeopleModel } from '../people-model';
import { PeopleService } from '../people.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';

const displayedColumnsLong = ['eid', 'firstlast', 'home_phone', 'status', 'details'];
const displayedColumnsShort = ['firstlast', 'status', 'details', ];

@Component({
  selector: 'app-peoples',
  templateUrl: './peoples.component.html',
  styleUrls: ['./peoples.component.css']
})
export class PeoplesComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  peopleSubscription: Subscription;
  dataSource = new MatTableDataSource<PeopleModel>();
  peoples;
  length = 0;
  pageSize = 25;
  pageSizeOptions = [10, 25, 50, 100];
  displayedColumns = displayedColumnsLong;

  //toolbar buttons properties
    //list display propery
  listSize = true; // true = normal text, small image / false = small but more text, large image

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private ps: PeopleService) { }

  ngOnInit() {
    this.getPeoples();
  }

  setListSize(isNormal: boolean) {
    this.listSize = isNormal;
    if (this.listSize) {
      this.displayedColumns = displayedColumnsLong;
    } else {
      this.displayedColumns = displayedColumnsShort;
    }
  }

  getPeoples(): void {
    this.peopleSubscription = this.ps.getPeoples().subscribe(
      (peoples: PeopleModel[]) => {
      this.dataSource.data = peoples;
      this.length = peoples.length;
      //console.log("Complete: "+ JSON.stringify(peoples))
    },
      (error) => console.log("error: "+ JSON.stringify(error))
    );
  }

}